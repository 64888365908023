   * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    --locked-color: #FD1414;

    --unlocked-color: #0FA958;
    --paide-color: #0000FF
    --middle-color: #ffffff;
  }
  
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 400px;
  }
  
  .lock {
    width: 48px;
    height: 41px;
    border: 3px solid var(--locked-color);
    background-color: var(--locked-color);
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
  }
  
  .lock:after {
    content: "";
    display: block;
    background: var(--middle-color);
    width: 6px;
    height: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -3.5px 0 0 -2px;
    transition: all 0.1s ease-in-out;
  }
  
  .lock:before {
    content: "";
    display: block;
    width: 23px;
    height: 23px;
    bottom: 100%;
    position: absolute;
    left: 50%;
    margin-left: -12px;
    border: 3px solid var(--locked-color);
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom: 0;
    background-color: transparent;
    transition: all 0.1s ease-in-out;
  }
  

  .unlocked {
    background-color: var(--unlocked-color);
    border-color: var(--unlocked-color);
  }
  
  .unlocked:before {
    bottom: 130%;
    left: 31%;
    margin-left: -11.5px;
    transform: rotate(-45deg);
    border-color: var(--unlocked-color);
    background-color: transparent;
  }
  
  .unlocked:after {
    background: var(--middle-color);
  }
  