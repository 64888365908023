::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
::-webkit-scrollbar-thumb {
  background: #bbb;
}

::-webkit-scrollbar-thumb:hover {
  background: #999; 
}


body {
  /* background : var(--color-bg); */
  background: '#f2f2f2';
  overflow-x: hidden;
}

a {
  color: unset;
  text-decoration: none;
}

.App{
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* min-height: 100vh; */
  /* background-color: rgb(248 250 252); */
  margin: 0px;
  padding: 0px;
  background-color: rgb(243,244,246);
  min-height: 100vh;
}

.navbar__bg {
  background: #031B34;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;
}

.gradient__bg  {
/* ff 3.6+ */
background:-moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

/* safari 5.1+,chrome 10+ */
background:-webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

/* opera 11.10+ */
background:-o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

/* ie 10+ */
background:-ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

/* global 92%+ browsers support */
background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

}

.hero__bg {
  background: rgb(255, 255, 255);
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 2rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }
  
  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }
  
  .section__margin {
    margin: 4rem 2rem;
  }
}


iframe#webpack-dev-server-client-overlay{display:none!important}

.slick-dots li button:before {
  font-size: 10px !important;
}