.modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .modal-content {
    max-height: 30vh;
    max-width: 50vw;
    margin: auto;
    padding: 20px;
    z-index: 1001;
    display: flex;
    flex-direction: column;
  }
  
  .modal-overlay-large {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .modal-content-large {
    max-height: 70vh;
    max-width: 60vw;
    margin: auto;
    padding: 20px;
    z-index: 1001;
    display: flex;
    flex-direction: column;
  }